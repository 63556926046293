
import taxImage1 from "../Assets/taximage1.jpg"
import taxImage2 from "../Assets/taximage2.jpg"
import taxImage3 from "../Assets/taximage3.jpg"
import taxImage4 from "../Assets/taximage4.jpg"
import taxImage5 from "../Assets/taximage5.jpg"
import taxImage6 from "../Assets/community.jpg"
import taxImage7 from "../Assets/chooseus.jpg"
import TawneeAndersonImage from "../Assets/TawneeAndersonPro.jpg"
import '../Styles/AboutUs.css'
function AboutUs(params) {
    return (
        <div className="AboutUs" >
            <div className="content">
                <div id="aboutusTitle" className=" upright">
                    <br></br>
                    <img src={TawneeAndersonImage}></img>
                    <div>
                        <h2>Welcome to Tawnee Andersons a Tax Professional with XIT Financial Services LLC - Your Trusted Partner in Tax Preparation!</h2>
                        At  Tawnee Andersons a Tax Professional with XIT Financial Services LLC, we are more than just a financial planning service; we are a family-owned and operated tax preparation business dedicated to helping individuals and families navigate the complexities of the tax landscape. With years of experience, a deep understanding of tax regulations, and a passion for delivering personalized services, we take pride in being the go-to resource for all your tax needs.

                    </div>
                </div>
                <div className="infoblock">
                    <div>
                        <h3>Our Story:</h3>
                       Tawnee began her journey doing taxes in 2023. She is currently a consultant with XIT Financial Services LLC. She is excited that she has been able to bring this skill back to the community she grew up in and loves. With Tawnee as your tax consultant you can expect honest, reliable, and compassionate tax assistance. Along with her tax experience Tawnee has a genuine desire to help others and looks forward to meeting and helping you too.
                    </div>  
                    <img src={taxImage2}></img>
                </div>
                <div className="infoblock">
                    <img src={taxImage1}></img>
                    <div>
                        <h3>Our Values:</h3>
                        Family-Centric Approach: We treat our clients like an extension of our family. Your financial success and peace of mind are at the forefront of everything we do.
                    </div>
                </div>
                <div className="infoblock">
                    <div>
                        <h3>Expertise:</h3> With a team of skilled professionals who stay up-to-date with the latest tax regulations, we ensure that you receive accurate advice and services tailored to your unique situation.
                    </div>
                    <img src={taxImage4}></img>
                </div>
                <div className="infoblock">
                    <img src={taxImage5}></img>
                    <div>
                        <h3>Integrity:</h3> Honesty and transparency form the bedrock of our services. You can trust us to handle your financial information with the utmost confidentiality and respect.
                    </div>
                </div>
                <div className="infoblock">
                    <div>
                        <h3>Personalized Attention:</h3> We understand that each client's financial situation is unique. That's why we take the time to listen, understand your goals, and customize our services to best meet your needs.
                    </div>
                    <img src={taxImage1}></img>
                </div>
                <div className="infoblock">
                    <img src={taxImage6}></img>
                    <div>
                        <h3>Community Focus:</h3> We are proud to be a part of this community, and giving back is important to us. We actively engage in local initiatives and events to contribute positively to the neighborhoods we serve.
                    </div>
                </div>
                <div className="infoblock">
                    <div>
                    <h3>Our Services:</h3>
                        From individual tax returns to small business tax planning, Tawnee Anderson  offers a comprehensive range of tax services designed to alleviate the stress of tax season and maximize your financial outcomes. Our services include:

                        Personal Income Tax Preparation
                        Small Business Tax Preparation and Consultation
                        Tax Problem Resolution
                        Year-Round Tax Advice
                    </div>
                    <img src={taxImage3}></img>
                </div>
                <div  className=" upright">
                    <div>
                        <h3>Why Choose Us:</h3>
                        When you choose Tawnee Anderson as your tax professional, you are not just selecting a tax preparation service; you are choosing a dedicated partner that has your best interests at heart. We are committed to providing accurate, timely, and trustworthy services that empower you to make informed financial decisions.
                        </div>
                    <img src={taxImage7}></img>
                </div>
                <div >
                    <h3>Contact Us:</h3>
                    <button onClick={() => params.setPath("ContactUs")}>Have a question?</button> Ready to get started? Reach out to our friendly team today at <a href="mailto:Tawnee.anderson@andersonstaxes.com" style={{ color: 'black' }}>Tawnee.anderson@andersonstaxes.com</a>. We offer a free initial consultation to understand your needs and explore how we can assist you.

                    Thank you for considering Tawnee Anderson as your tax preparation partner. We look forward to helping you achieve your financial goals and ensuring a stress-free tax experience for you and your family.
                    Warm regards,

                    Tawnee and Darren Anderson

                </div>
            </div>
        </div>
    )
}

export default AboutUs;