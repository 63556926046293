
import '../Styles/Footer.css'
function Footer(params) {
    return (
        <div className="Footer">
            <div className='footerContainer'>

                <div className="footerContactContainer">
                    <h2>Contact Us</h2>
                    <div>
                        <h3>Email:</h3>
                        <a href="mailto:tawnee.anderson@andersontaxes.com">Tawnee.anderson@andersontaxes.com</a>
                    </div>
                    <div>
                        <h3>Phone:</h3>
                        <a href="callto:8013729613">(435) 314-8330</a>
                    </div>
                    <div>
                        <h3>Office Address:</h3>
                        <h4><a href="https://maps.app.goo.gl/5PVK1FMCpXsXjWJW7" target="_blank">7593 Cedar Ave, Brownsville, TX 78526</a></h4>
                        <iframe target="_blank" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.5280794325918!2d-97.51977459999999!3d26.0162722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866f974a529e5c83%3A0x6c092a6836d8c5a6!2s7593%20Cedar%20Ave%2C%20Brownsville%2C%20TX%2078526!5e0!3m2!1sen!2sus!4v1704857320292!5m2!1sen!2sus" width="95%" height="95%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div>
                    <h2>External Resources</h2>
                    <h3>
                        <a href="https://www.irs.gov" target="_blank">Irs.gov</a>
                    </h3>
                    <h3>
                        <a href="https://www.irs.gov/refunds" target="_blank">Track your return</a>
                    </h3>
                    <h3>
                        <a href="https://www.irs.gov/help/irs2goapp" target="_blank">Track my refund</a>
                    </h3>
                    <h3>
                        <a href=" https://www.nerdwallet.com/article/taxes/federal-income-tax-brackets" target="_blank">
                            Tax income bracket</a>
                    </h3>
                </div>
            </div>
            <h1>Anderson Taxes</h1>

        </div>
    )
}

export default Footer;